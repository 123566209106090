import { Text, Icon, HStack } from '@chakra-ui/react';

const SideBarMenuItem = ({ icon, text, onMenuClick }) => {
  const category = {
    color: 'colors.darkestTealGrey',
    hover: {
      color: 'colors.mrmTeal',
    },
  };

  const handleMenuClick = () => {
    onMenuClick(text);
  };

  return (
    <HStack
      align="center"
      _hover={category.hover}
      color={category.color}
      cursor="pointer"
    >
      <Icon as={icon} boxSize="1.2em" alt="Icon" data-testid="sideBarIcon" />
      <Text
        onClick={handleMenuClick}
        fontSize="lg"
        _hover={category.hover}
        data-testid="sideBarText"
        fontWeight={500}
      >
        {text}
      </Text>
    </HStack>
  );
};

export default SideBarMenuItem;
