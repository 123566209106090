import { React, useEffect, useState } from 'react';
import Header from '../components/header/Header';
import WelcomeHeading from '../components/header/WelcomeHeading';
import AppsContainer from '../components/appCards/AppsContainer';
import SideBarMenu from '../components/menus/SideBarMenu';
import { Flex } from '@chakra-ui/react';
import { appCardData } from '../data/appCardData';
import { breakpointsAsNumbers } from '../styles/theme';

const PortalContainer = () => {
  //* Uncomment code in this file to restrict access to the user impersonation app.

  const [appCards, setAppCards] = useState(appCardData);
  const [isUserImpersonate, setIsUserImpersonate] = useState();
  const [pageWidth, setPageWidth] = useState(0);

  // const { getAccessTokenSilently } = useAuth0();

  // const handleIsUserImpersonate = (isImpersonation) => {
  //     setIsUserImpersonate(isImpersonation);
  // }

  // useEffect(() => {
  //     UserRoleService(getAccessTokenSilently, handleIsFetching, handleIsUserImpersonate)
  //     },[]);

  const onMenuClick = selected => {
    let selectedCards;

    if (selected === 'All') {
      selectedCards = appCardData;
    } else {
      selectedCards = appCardData.filter(card => {
        return card.menu === selected;
      });
    }

    return setAppCards(selectedCards);
  };

  const handleWidth = () => {
    setPageWidth(window.outerWidth);
  };

  useEffect(() => {
    handleWidth();
    window.addEventListener('resize', handleWidth);

    return () => window.removeEventListener('resize', handleWidth);
  }, []);

  const smallScreen = pageWidth < breakpointsAsNumbers.lg;

  return (
    <>
      <Header />
      <Flex
        direction={'column'}
        w={'100vw'}
        h={'calc(100vh -60px)'}
        pt={smallScreen ? 16 : 20}
        pl={smallScreen ? 16 : 20}
        pr={smallScreen ? 10 : 20}
        pb={smallScreen ? 10 : 20}
        color={'colors.darkestTealGrey'}
      >
        <Flex>
          <Flex direction={'column'} w={smallScreen ? '30%' : '20%'} pt="5">
            <SideBarMenu
              onMenuClick={onMenuClick}
              isUserImpersonate={isUserImpersonate}
            />
          </Flex>
          <Flex
            direction={'column'}
            w={smallScreen ? '70%' : '80%'}
            gap={smallScreen ? 6 : 10}
          >
            <WelcomeHeading />
            <AppsContainer
              appCards={appCards}
              isUserImpersonate={isUserImpersonate}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PortalContainer;
