import React from 'react';
import { Card, CardBody, CardFooter } from '@chakra-ui/card';
import { VStack, Text, Icon, Link } from '@chakra-ui/react';
import { cardStyle, cardBody, icon, cardFoot } from '../../styles/theme';

const AppCard = ({ cardName, cardDescription, cardLink, cardIcon }) => {
  return (
    <>
      <Card
        flexShrink="0"
        as={cardStyle.as}
        h={cardStyle.h}
        bg={cardStyle.bg}
        w={cardStyle.w}
        m={cardStyle.m}
        borderRadius={cardStyle.radius}
        boxShadow={cardStyle.boxShadow}
        transition={cardStyle.transition}
        _hover={{ transform: cardStyle.transform }}
      >
        <Link
          href={cardLink}
          _hover={{ textDecor: 'none' }}
          isExternal
          w="100%"
          h="100%"
        >
          <CardBody
            h={cardBody.h}
            w={cardBody.w}
            borderTop={cardBody.borderTop}
            borderX={cardBody.borderX}
            bgGradient={'linear(to-b, #01111c, #2b5663)'}
            borderTopRadius={cardBody.borderRadius}
          >
            <VStack mt={icon.mt}>
              <Icon as={cardIcon} h={icon.h} w={icon.w} color={icon.color} />
            </VStack>
          </CardBody>
          <CardFooter
            bg={cardFoot.bg}
            p={cardFoot.p}
            h={cardFoot.h}
            w={cardFoot.w}
            border={cardFoot.border}
            color={cardFoot.color}
            borderBottomRadius={cardFoot.borderRadius}
          >
            <VStack
              align={cardFoot.align}
              spacing={cardFoot.spacing}
              pl={cardFoot.pl}
            >
              <Text
                fontWeight={cardFoot.fontWeightHeading}
                align={cardFoot.align}
                fontSize={cardFoot.heading}
              >
                {cardName}
              </Text>
              <Text
                fontWeight={cardFoot.fontWeightDescription}
                align={cardFoot.align}
                fontSize={cardFoot.description}
              >
                {cardDescription}
              </Text>
            </VStack>
          </CardFooter>
        </Link>
      </Card>
    </>
  );
};

export default AppCard;
