import { FiUpload, FiPrinter, FiUsers, FiUploadCloud } from 'react-icons/fi';
import { MdFormatShapes } from 'react-icons/md';
import { TbWorld } from 'react-icons/tb';
import { settings } from '../settings';
import { Order } from '../components/svg/Order';

const {
  contentUpload,
  userImpersonation,
  printRouting,
  managePrinters,
  assetManagement,
  manageFormatCategories,
  multiMarket,
  menuMakerOrders,
  menuMakerAdmin,
} = settings;

export const appCardData = [
  {
    id: 1,
    name: 'Content Upload',
    description: 'Upload images for POS and menu templates',
    link: contentUpload,
    icon: FiUpload,
    menu: 'Content',
    restrict: false,
  },
  {
    id: 2,
    name: 'User Impersonation',
    description: 'Impersonate a user account',
    link: userImpersonation,
    icon: FiUsers,
    menu: 'Users',
    restrict: false,
  },
  {
    id: 3,
    name: 'Print Routing',
    description: 'Send orders to print',
    link: printRouting,
    icon: FiPrinter,
    menu: 'Print',
    restrict: false,
  },
  {
    id: 4,
    name: 'Manage Printers',
    description: "View existing printers, and set up new 'Synergy' printers",
    link: managePrinters,
    icon: FiPrinter,
    menu: 'Print',
    restrict: false,
  },
  {
    id: 5,
    name: 'Asset Management',
    description: 'Manage Existing Assets',
    link: assetManagement,
    icon: FiUploadCloud,
    menu: 'Assets',
    restrict: false,
  },
  {
    id: 6,
    name: 'Manage Format Categories',
    description: 'Create and edit formats and categories',
    link: manageFormatCategories,
    icon: MdFormatShapes,
    menu: 'Formats',
    restrict: false,
  },
  {
    id: 7,
    name: 'Market Selector',
    description: 'Switch between markets for any user with multi-market access',
    link: multiMarket,
    icon: TbWorld,
    menu: 'Markets',
    restrict: false,
  },
  {
    id: 8,
    name: 'Order Admin',
    description: 'View and manage orders placed on Hyble platform',
    link: menuMakerOrders,
    icon: Order,
    menu: 'Orders',
    restrict: false,
  },
  {
    id: 9,
    name: 'Market Admin',
    description: 'View and manage markets on the hyble platform',
    link: menuMakerAdmin,
    icon: TbWorld,
    menu: 'Markets',
    restrict: false,
  },
];
