//* API
const API_HOST_V1 =
  process.env.REACT_APP_API_HOST_V1 || window.ENV_CONFIG.hosts.api_v1;

//* UserRoleService
const API_HOST_V1_PROFILE = `${API_HOST_V1}/Profile`;

//* External Links
const CONTENT_UPLOAD =
  process.env.REACT_APP_CONTENT_UPLOAD_DEV ||
  window.ENV_CONFIG.link.contentUpload;
const USER_IMPERSONATION =
  process.env.REACT_APP_USER_IMPERSONATION_DEV ||
  window.ENV_CONFIG.link.userImpersonation;
const PRINT_ROUTING =
  process.env.REACT_APP_PRINT_ROUTING_DEV ||
  window.ENV_CONFIG.link.printRouting;
const MANAGE_PRINTERS =
  process.env.REACT_APP_MANAGE_PRINTERS_DEV ||
  window.ENV_CONFIG.link.managePrinters;
const ASSET_MANAGEMENT =
  process.env.REACT_APP_ASSET_MANAGEMENT_DEV ||
  window.ENV_CONFIG.link.assetManagement;
const MANAGE_FORMAT_CATEGORIES =
  process.env.REACT_APP_MANAGE_FORMAT_CATEGORIES ||
  window.ENV_CONFIG.link.manageFormatCategories;
const MULTI_MARKET =
  process.env.REACT_APP_MULTI_MARKET || window.ENV_CONFIG.link.multiMarket;

const MENU_MAKER_ORDERS =
  process.env.REACT_APP_MENU_MAKER_ORDERS_DEV ||
  window.ENV_CONFIG.link.menuMakerOrders;

const MENU_MAKER_ADMIN =
  process.env.REACT_APP_MENU_MAKER_ADMIN_DEV ||
  window.ENV_CONFIG.link.menuMakerAdmin;

//* Auth0 Config
const AUTH0_AUDIENCE =
  process.env.REACT_APP_AUTH0_AUDIENCE_DEV || window.ENV_CONFIG.auth0.audience;
const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN_DEV || window.ENV_CONFIG.auth0.domain;
const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID_DEV ||
  window.ENV_CONFIG.auth0.client_id;

export const settings = {
  apiHostV1: API_HOST_V1,
  contentUpload: CONTENT_UPLOAD,
  userImpersonation: USER_IMPERSONATION,
  printRouting: PRINT_ROUTING,
  managePrinters: MANAGE_PRINTERS,
  apiV1Profile: API_HOST_V1_PROFILE,
  audience: AUTH0_AUDIENCE,
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  assetManagement: ASSET_MANAGEMENT,
  manageFormatCategories: MANAGE_FORMAT_CATEGORIES,
  multiMarket: MULTI_MARKET,
  menuMakerOrders: MENU_MAKER_ORDERS,
  menuMakerAdmin: MENU_MAKER_ADMIN,
};
