import React from 'react';
import { Flex } from '@chakra-ui/react';
import AppCardItems from './AppCardItems';

const AppsContainer = ({ appCards, isUserImpersonate }) => {
  return (
    <Flex
      direction={'column'}
      p="10px"
      borderRadius="15px"
      boxShadow={'inset 0 0 10px rgba(0, 0, 0, 0.25)'}
      h={'70vh'}
      overflow={'scroll'}
      css={{
        '&::-webkit-scrollbar': {
          bg: 'colors.mrmTeal',
          width: '4px',
          height: '5px',
          borderRadius: '100px',
        },
        '&::-webkit-scrollbar-track': {
          width: '3px',
          margin: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#006873',
          borderRadius: '15px',
        },
      }}
    >
      <AppCardItems appCards={appCards} isUserImpersonate={isUserImpersonate} />
    </Flex>
  );
};

export default AppsContainer;
