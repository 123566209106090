import React, { useEffect } from 'react';
import PortalContainer from './containers/PortalContainer';
import { useAuth0 } from '@auth0/auth0-react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { colors } from './theme';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const theme = extendTheme({
    colors: {
      colors,
    },
  });

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  return (
    <ChakraProvider theme={theme}>
      <PortalContainer />
    </ChakraProvider>
  );
}

export default App;
