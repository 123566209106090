import React from 'react';
import { VStack } from '@chakra-ui/react';
import SideBarMenuItem from './SideBarMenuItem';
import { sideBarData } from '../../data/sideBarData';

const SideBarMenu = ({ onMenuClick, isUserImpersonate }) => {
  let menuItems;

  if (isUserImpersonate === true) {
    menuItems = sideBarData.map(option => {
      return (
        <SideBarMenuItem
          key={option.id}
          icon={option.icon}
          text={option.text}
          onMenuClick={onMenuClick}
        />
      );
    });
  } else {
    const restrictItems = sideBarData.filter(option => {
      return option.restrict === false;
    });
    menuItems = restrictItems.map(option => {
      return (
        <SideBarMenuItem
          key={option.id}
          icon={option.icon}
          text={option.text}
          onMenuClick={onMenuClick}
        />
      );
    });
  }

  return (
    <VStack align="start" spacing="4" >
      {menuItems}
    </VStack>
  );
};

export default SideBarMenu;
