import React from 'react';

export default function HybleLogo() {
  return (
    <svg
      width="25"
      height="30"
      viewBox="0 0 107 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.3503 21.2496C63.734 21.2496 60.8102 24.0726 60.8102 27.5628V42.9354C60.8102 47.4522 56.963 51.1478 52.2183 51.1478C47.4735 51.1478 43.6264 47.4522 43.6264 42.9354C43.6264 42.7814 43.6264 42.6274 43.6264 42.4478L43.4212 39.8814C43.2929 28.5124 33.5725 19.2735 21.7491 19.2735C18.7996 19.2735 15.8501 19.8637 13.1572 20.9929V6.31327C13.0802 2.82301 10.1564 0 6.54011 0C2.92381 0 0 2.82301 0 6.31327V39.7274C0 39.7274 0 39.9584 0 40.0867V55.4593C0 58.9496 2.92381 61.7726 6.54011 61.7726C10.1564 61.7726 13.0802 58.9496 13.0802 55.4593V40.0867C13.0802 35.5699 16.9273 31.8743 21.6721 31.8743C26.4169 31.8743 30.264 35.5442 30.264 40.0867C30.264 40.2407 30.264 40.4204 30.264 40.5743L30.4692 43.1407C30.5974 54.5097 40.3178 63.7487 52.1413 63.7487C55.1421 63.7487 58.0146 63.1584 60.7332 62.0292V66.1354C60.7332 70.6522 56.8861 74.3478 52.1413 74.3478C48.525 74.3478 45.6012 77.1708 45.6012 80.6611C45.6012 84.1513 48.525 86.9743 52.1413 86.9743C64.093 86.9743 73.8391 77.6327 73.8391 66.1354V27.5628C73.8391 24.0726 70.9153 21.2496 67.299 21.2496H67.3503Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16044_4191"
          x1="0"
          y1="43.5"
          x2="73.8904"
          y2="43.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009EB5" />
          <stop offset="0.98" stopColor="#00CC7C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

// import { Icon } from '@chakra-ui/react';

// export const LogoWhite = () => {

//   const color = {
//     color: '#F5FDFF',
//     _hover: {
//       color: '#80e8ff'
//     }}

//   return(
//     <Icon
//       viewBox='0 0 107 59'
//       boxSize='1.25em'
//       color={ color }
//     >
//       <path
//         d='M35 0L0 59H27L48.5 22.5L35 0Z'
//         fill='currentColor'
//       />
//       <path
//         d='M53.5 21.5L32.5 59H44L53.5 42L59.5 52.5L65 42.5L53.5 21.5Z'
//         fill='currentColor'
//       />
//       <path
//         d='M107 59L72 0L58.5 22.5L80 59H107Z'
//         fill='currentColor'
//       />
//     </Icon>
//   );
// }

// export default LogoWhite;
