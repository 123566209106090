import React from 'react';
import AppCard from './AppCard';
import { Flex } from '@chakra-ui/react';

const AppCardItems = ({ appCards, isUserImpersonate }) => {
  let appCardList;

  if (isUserImpersonate === true) {
    appCardList = appCards.map(card => {
      return (
        <AppCard
          key={card.id}
          cardName={card.name}
          cardDescription={card.description}
          cardLink={card.link}
          cardIcon={card.icon}
        />
      );
    });
  } else {
    const restrictCards = appCards.filter(card => {
      return card.restrict === false;
    });
    appCardList = restrictCards.map(card => {
      return (
        <AppCard
          key={card.id}
          cardName={card.name}
          cardDescription={card.description}
          cardLink={card.link}
          cardIcon={card.icon}
        />
      );
    });
  }

  return <Flex flexWrap={'wrap'}>{appCardList}</Flex>;
};

export default AppCardItems;
