import React from 'react';
import { Flex, Spacer, Button, Box, Link, Icon } from '@chakra-ui/react';

import { useAuth0 } from '@auth0/auth0-react';

import HybleLogo from '../svg/HybleLogo';
import Logout from '../svg/Logout';
import QuestionMark from '../svg/QuestionMark';

const Header = () => {
  const { logout } = useAuth0();
  const restartProcess = () => window.location.reload(true);

  return (
    <Flex
      h="60px"
      bg="colors.darkestTeal"
      alignItems="center"
      zIndex="10"
      px="24px"
      position={'sticky'}
      top={0}
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.25)"
    >
      <Button
        onClick={restartProcess}
        background="none"
        padding="0"
        _hover={{ bgColor: 'none' }}
        _active={{ bgColor: 'none' }}
      >
        <HybleLogo />
      </Button>

      <Spacer />

      <Flex
        align={'center'}
        gap={4}
        style={{ cursor: 'pointer' }}
        color={'colors.medDarkTeal'}
      >
        <Box _hover={{ color: 'colors.mrmTeal' }}>
          <Link
            href={'https://support.hyble.tech/'}
            isExternal
            _hover={{ color: 'colors.mrmTeal' }}
            data-heap="header_supportButton"
            data-testid="support-button"
            rel="noreferrer"
          >
            <Icon w="24px" h="24px" as={QuestionMark} />
          </Link>
        </Box>

        <Box
          onClick={() => logout({ returnTo: window.location.origin })}
          _hover={{ color: 'colors.mrmTeal' }}
        >
          <Icon data-testid="header-logout" as={Logout} w="24px" h="24px" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Header;
