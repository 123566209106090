export const cardStyle = {
  as: 'button',
  h: '280px',
  w: '200px',
  m: '10px',
  radius: '8px',
  boxShadow: '5px 5px 10px 5px rgba(0, 0, 0, 0.25)',
  transform: 'scale(1.05)',
  transition: 'transform 0.3s ease',
};

export const cardBody = {
  borderRadius: '8px',
  h: '50%',
  w: '100%',
  align: 'center',
};

export const icon = {
  h: '50px',
  w: 'auto',
  color: '#f5fdff',
  mt: '15%',
  hover: {
    h: '70px',
  },
};

export const cardFoot = {
  h: '50%',
  w: '100%',
  p: '8px',
  pl: '8px',
  bg: '#f5fdff',
  align: 'start',
  borderRadius: '8px',
  color: '#030F18',
  heading: 'md',
  description: 'sm',
  fontWeightHeading: '600',
  fontWeightDescription: '400',
  spacing: '4px',
};

export const breakpointsAsNumbers = {
  sm: 320,
  md: 768,
  lg: 1024,
  xl: 1400,
  '2xl': 1536,
};
