export const colors = {
  primary: '#009FB5',
  secondary: '#0A92A5',
  white: '#F5F5F5',
  black: '#111827',
  error: '#EF4444',
  lightestGrey: '#E8E8E8',
  lightTealGrey: '#BFC9CB',
  darkTealGrey: '#363D41',
  medDarkTeal: '#0A92A5',
  medTeal: '#009FB5',
  mrmTeal: '#006873',
  darkestTeal: '#0B252B',
  darkestTealGrey: '#21292D',
  charcoalGrey: '#84878B',
  teal: '#80e8ff',
};
