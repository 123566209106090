import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react"
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
import { settings } from './settings';


root.render(
  <StrictMode>
    <Auth0Provider
      audience={ settings.audience }
      domain={ settings.domain }
      clientId={ settings.clientId }
      redirectUri={ window.location.origin }
    >
    <ChakraProvider>
      <ColorModeScript/>
      <App/>
    </ChakraProvider>
  </Auth0Provider>
  </StrictMode >
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
