import { FiImage, FiPrinter, FiUsers, FiUploadCloud } from 'react-icons/fi';
import LogoWhite from '../components/svg/LogoWhite';
import { TbWorld } from 'react-icons/tb';
import { MdFormatShapes } from 'react-icons/md';
import { Order } from '../components/svg/Order';

export const sideBarData = [
  {
    id: 1,
    icon: LogoWhite,
    text: 'All',
    restrict: false,
  },
  {
    id: 2,
    icon: FiImage,
    text: 'Content',
    restrict: false,
  },
  {
    id: 3,
    icon: FiPrinter,
    text: 'Print',
    restrict: false,
  },
  {
    id: 4,
    icon: FiUsers,
    text: 'Users',
    restrict: false,
  },
  {
    id: 5,
    icon: FiUploadCloud,
    text: 'Assets',
    restrict: false,
  },
  {
    id: 6,
    icon: MdFormatShapes,
    text: 'Formats',
    restrict: false,
  },
  {
    id: 7,
    icon: TbWorld,
    text: 'Markets',
    restrict: false,
  },
  {
    id: 7,
    icon: Order,
    text: 'Orders',
    restrict: false,
  },
];
