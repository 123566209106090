import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Order = createIcon({
  displayName: 'Order',
  viewBox: '0 0 24 24',
  path: [
    <g key="1" clipPath="url(#clip0_9753_12517)">
      <path
        d="M22.824 7.52566C22.8206 7.42625 22.7965 7.32852 22.753 7.23832C22.753 7.23832 22.753 7.16991 22.753 7.14254C22.6857 7.02477 22.588 6.92578 22.4693 6.8552L12.383 1.20416C12.2441 1.12988 12.0879 1.09091 11.9291 1.09091C11.7702 1.09091 11.614 1.12988 11.4751 1.20416L1.44556 6.92361C1.32689 6.9942 1.22915 7.09318 1.16184 7.21096C1.15719 7.24272 1.15719 7.27497 1.16184 7.30674C1.11839 7.39694 1.09426 7.49467 1.09091 7.59408C1.09091 7.59408 1.09091 7.59408 1.09091 7.59408V17.6784C1.09441 17.8288 1.13801 17.9758 1.21753 18.1052C1.29704 18.2346 1.40977 18.3421 1.54487 18.4173L11.5602 23.8904C11.6948 23.9623 11.8462 24 12 24C12.1539 24 12.3052 23.9623 12.4398 23.8904L22.4551 18.4173C22.5902 18.3421 22.703 18.2346 22.7825 18.1052C22.862 17.9758 22.9056 17.8288 22.9091 17.6784V7.59408C22.9091 7.59408 22.824 7.55303 22.824 7.52566ZM11.9291 2.9282L15.1351 4.74803L6.8079 9.35917L3.65859 7.59408L11.9291 2.9282ZM11.0495 21.6464L2.77905 17.1721V9.08551L11.0495 13.7651V21.6464ZM11.9291 12.2189L8.58116 10.3443L16.9084 5.7332L20.1996 7.59408L11.9291 12.2189ZM21.0791 17.1721L12.8228 21.6464V13.7651L21.0791 9.15393V17.1721Z"
        fill="currentColor"
      />
    </g>,
    <defs key="2">
      <clipPath id="clip0_9753_12517">
        <rect width="24" height="24" fill="transparent" />
      </clipPath>
    </defs>,
  ],
});
